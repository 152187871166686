import React from 'react';

function PageNotFound() {
  return (
    <div>

    </div>
  );
}

export default PageNotFound;
